import React, { FunctionComponent, useContext } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import { Link } from "../Link";
// @ts-ignore
import BlockContent from "@sanity/block-content-to-react";
import sanityImageUrlBuilder from "../../utils/imageUrlBuilder";
import { useSiteMetadata } from "../../hooks/useSiteMetadata";
import { ImageInterface, PortableText, MultiLineHeadline, Slug } from "../../types/SanityTypes";
import { LocalizedContext } from "../../services/LocalizedContextService";
import "./styles.scss";

export interface ChildPageTeaserInterface {
  _id?: string;
  name?: string;
  teaser?: {
    teaserHeadline: MultiLineHeadline;
    teaserCTALabel: string;
    _rawTeaserHeroImage: ImageInterface;
    _rawTeaserSecondaryImage?: ImageInterface;
    _rawTeaserText: PortableText;
    // For Preview
    teaserHeroImage?: ImageInterface;
    teaserSecondaryImage?: ImageInterface;
    teaserText?: PortableText;
  };
  slug: {
    current: string;
  };
  parentSection?: Slug & {
    main: {
      sectionColor: {
        value: string;
      };
    };
  };
}

const ChildPageTeaser: FunctionComponent<ChildPageTeaserInterface> = ({ _id, name, teaser, slug, parentSection }) => {
  const { sanityProductLandingPage } = useContext(LocalizedContext);
  const { sanityId, sanityDataset } = useSiteMetadata();
  const urlBuilder = sanityImageUrlBuilder({
    projectId: sanityId,
    dataset: sanityDataset
  });

  const heroImage = teaser?._rawTeaserHeroImage || teaser?.teaserHeroImage;
  const secondaryImage = teaser?._rawTeaserSecondaryImage || teaser?.teaserSecondaryImage;
  const text = teaser?._rawTeaserText || teaser?.teaserText;

  const heading = () => {
    if (teaser?.teaserHeadline.tertiaryText)
      return (
        teaser.teaserHeadline.primaryText +
        " " +
        teaser.teaserHeadline.secondaryText +
        " " +
        teaser.teaserHeadline.tertiaryText
      );
    if (teaser?.teaserHeadline.secondaryText)
      return teaser.teaserHeadline.primaryText + " " + teaser.teaserHeadline.secondaryText;

    return teaser?.teaserHeadline.primaryText;
  };

  const headingColor = parentSection ? parentSection.main.sectionColor.value : "var(--orange-bright-color)";

  const isPromotigoTemplate = name?.toLocaleLowerCase().includes('promotigo');
  const parentSlug = parentSection ? parentSection.slug.current : (isPromotigoTemplate ? ('') : (sanityProductLandingPage?.slug.current));

  return (
    <section data-testid="child-page-teaser" className="child-page-teaser">
      <Container fluid>
        {secondaryImage?.asset ? (
          <div className="headline-centre">
            <Row className="justify-content-end">
              <Col lg={4} sm={6} xs={6}>
                <div className="secondary-image-position">
                  {heroImage && (
                    <picture
                      data-testid="secondary-image"
                      style={{
                        paddingTop: `calc(18% / ${heroImage.asset.metadata?.dimensions.aspectRatio})`,
                        paddingBottom: `calc(20% / ${heroImage.asset.metadata?.dimensions.aspectRatio})`,
                        background: `url(${heroImage.asset.metadata?.lqip})`,
                        backgroundSize: "cover"
                      }}
                    >
                      <source
                        media={"(min-width: 1200px)"}
                        srcSet={
                          urlBuilder
                            .image(heroImage)
                            .auto("format")
                            .quality(80)
                            .width(1000)
                            .height(800)
                            .format("webp")
                            .url() as string
                        }
                      />
                      <source
                        media={"(min-width: 768px)"}
                        srcSet={
                          urlBuilder
                            .image(heroImage)
                            .auto("format")
                            .quality(80)
                            .width(800)
                            .height(600)
                            .format("webp")
                            .url() as string
                        }
                      />
                      <img
                        className="img-fluid"
                        src={
                          urlBuilder
                            .image(heroImage)
                            .auto("format")
                            .quality(80)
                            .width(500)
                            .height(400)
                            .format("webp")
                            .url() as string
                        }
                        alt={heroImage.alt}
                        loading={"lazy"}
                      />
                    </picture>
                  )}
                </div>
              </Col>
              <Col lg={5} sm={6} xs={6} className="align-self-end">
                {secondaryImage && (
                  <picture
                    data-testid="primary-image"
                    className="placeholder"
                    style={{
                      paddingTop: `calc(40% / ${secondaryImage.asset.metadata?.dimensions.aspectRatio})`,
                      paddingBottom: `calc(40% / ${secondaryImage.asset.metadata?.dimensions.aspectRatio})`,
                      background: `url(${secondaryImage.asset.metadata?.lqip})`,
                      backgroundSize: "cover"
                    }}
                  >
                    <source
                      media={"(min-width: 1200px)"}
                      srcSet={
                        urlBuilder
                          .image(secondaryImage)
                          .auto("format")
                          .quality(80)
                          .width(600)
                          .height(1000)
                          .format("webp")
                          .url() as string
                      }
                    />
                    <source
                      media={"(min-width: 768px)"}
                      srcSet={
                        urlBuilder
                          .image(secondaryImage)
                          .auto("format")
                          .quality(80)
                          .width(500)
                          .height(800)
                          .format("webp")
                          .url() as string
                      }
                    />
                    <img
                      className="img-fluid"
                      src={
                        urlBuilder
                          .image(secondaryImage)
                          .auto("format")
                          .quality(80)
                          .width(400)
                          .height(700)
                          .format("webp")
                          .url() as string
                      }
                      alt={secondaryImage.alt}
                      loading={"lazy"}
                    />
                  </picture>
                )}
              </Col>
            </Row>
            <Row>
              <Col lg={3} sm={12} xs={12} className="align-self-center">
                <div className="text-content">
                  <h2 data-testid="cpt-heading-color" style={{ color: headingColor }}>
                    {heading()}
                  </h2>
                  <div className="text-space">
                    <BlockContent blocks={text} />
                  </div>
                  <Link _id={_id} to={`${parentSlug}/${slug.current}`} className="btn-primary">
                    {teaser?.teaserCTALabel}
                  </Link>
                </div>
              </Col>
            </Row>
          </div>
        ) : (
          <Row className="no-border-right">
            <Col lg={5}>
              {heroImage && (
                <picture
                  className="placeholder"
                  data-testid="primary-image"
                  style={
                    heroImage.asset.metadata
                      ? {
                          paddingTop: `calc(28% / ${heroImage.asset.metadata.dimensions.aspectRatio})`,
                          paddingBottom: `calc(28% / ${heroImage.asset.metadata.dimensions.aspectRatio})`,
                          background: `url(${heroImage.asset.metadata.lqip})`,
                          backgroundSize: "cover"
                        }
                      : undefined
                  }
                >
                  <source
                    media={"(min-width: 1200px)"}
                    srcSet={
                      urlBuilder
                        .image(heroImage)
                        .auto("format")
                        .quality(80)
                        .width(700)
                        .height(900)
                        .format("webp")
                        .url() || undefined
                    }
                  />
                  <source
                    media={"(min-width: 768px)"}
                    srcSet={
                      urlBuilder
                        .image(heroImage)
                        .auto("format")
                        .quality(80)
                        .width(500)
                        .height(700)
                        .format("webp")
                        .url() || undefined
                    }
                  />
                  <img
                    src={
                      urlBuilder
                        .image(heroImage)
                        .auto("format")
                        .quality(80)
                        .width(500)
                        .height(700)
                        .format("webp")
                        .url() || undefined
                    }
                    alt={heroImage.alt}
                    className="img-fluid img-center"
                  />
                </picture>
              )}
            </Col>
            <Col lg={{ span: 6, offset: 1 }} className="text-box">
              <h2 data-testid="cpt-heading-color" style={{ color: headingColor }}>
                {heading()}
              </h2>
              <div className="text-space">
                <BlockContent blocks={text} />
              </div>
              <Link _id={_id} to={`${parentSlug}/${slug.current}`} className="btn-primary">
                {teaser?.teaserCTALabel}
              </Link>
            </Col>
          </Row>
        )}
      </Container>
    </section>
  );
};

export default ChildPageTeaser;
